import { Link } from 'gatsby'
import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'

import Content from '../components/container'
import Layout from '../components/layout'
import banner from '../images/banner-primary.jpg'
import comissario from '../images/comissario-de-voo.jpg'
import judo from '../images/judo.svg'
import manutencao from '../images/manutencao-aeronautica.jpg'
import natacao from '../images/natacao.svg'
import piloto from '../images/piloto-privado.jpg'
import tenis from '../images/tenis.svg'
import volei from '../images/volei.svg'

const Banner = styled.div`
  width: 100%;
  height: 440px;
  background-image: url(${banner});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`

const BannerOverlay = styled.div`
  width: 100%;
  height: 440px;
  background-color: rgba(0, 0, 40, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 5px 20px;

  @media (min-width: 850px) {
    padding: 50px 70px;
  }
`

const BannerTitle = styled.h1`
  color: #fff;
  font-weight: 600;
  text-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.4);
  text-align: right;
  font-size: 40px;

  @media (min-width: 850px) {
    font-size: 60px;
  }
`

const BannerText = styled.p`
  color: #fff;
  max-width: 600px;
  text-align: right;
  font-weight: 500;
  text-shadow: 0 0.3rem 1rem rgba(0, 0, 0, 0.7);
  font-size: 12px;

  @media (min-width: 850px) {
    font-size: 14px;
  }
`

const Presentation = styled(Content)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0 60px 0;
`

const Title = styled.h1`
  color: #102549;
  font-size: 32px;
  font-weight: 600;
`

const Subtitle = styled.p``

const Courses = styled.div`
  width: 100%;
  background-color: #f7faff;
`

const CoursesContent = styled(Content)`
  padding: 50px 0 70px 0;

  .row {
    .col {
      display: flex;
      justify-content: center;
      margin: 15px 0;

      .card {
        width: 100%;
        min-width: 250px;
        overflow: hidden;
        border: 0;
      }
    }
  }
`

const CardImage = styled.div`
  height: 190px;
  width: 100%;
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`

const CardMore = styled(Link)`
  color: #274477;
`

const Sports = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0 60px 0;
`

const SportsContent = styled.div`
  width: 100%;
  max-width: 720px;
  margin: 9px auto 26px auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`

const SportBox = styled.div`
  background-color: ${props => props.color};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  border-radius: 20px;
  margin: 15px;
`

const SportImg = styled.img`
  width: 70px;
  height: 50px;
`

const SportName = styled.p`
  color: #fff;
  margin: 8px 0 0 0;
  font-weight: 500;
`

const SportsMore = styled(Link)`
  color: #274477;
`

const aspectRatio = 1920 / 1080

const IndexPage = () => {
  const [videoWidth, setVideoWidth] = useState(0)

  const presentationRef = useRef(null)

  useEffect(() => {
    const pres = presentationRef.current

    const updateSize = () => {
      const width = pres.offsetWidth
      setVideoWidth(width > 720 ? 720 : width)
    }

    updateSize()

    if (window) window.addEventListener('resize', updateSize)
  }, [presentationRef.current])

  return (
    <Layout title='Home' page='home'>
      <Banner>
        <BannerOverlay>
          <BannerTitle>Bem-vindo ao Aeroclube!</BannerTitle>
          <BannerText>
            Clube fundado em 11 de fevereiro de 1928, é oficialmente inaugurado no dia 29
            de dezembro de 1928, com a assinatura da ATA pelos diretores, autoridades,
            convidados e sócios. Este projeto foi impulsionado pela doação do terreno
            localizado na Av. Hermes da Fonseca, em 17 de fevereiro de 1928, pelo então
            Governador Juvenal Lamartine.
          </BannerText>
        </BannerOverlay>
      </Banner>
      <Presentation ref={presentationRef}>
        <Title>Sobre Nós</Title>
        <Subtitle>Assista nosso vídeo institucional</Subtitle>
        <iframe
          width={videoWidth}
          height={videoWidth / aspectRatio}
          className='mt-3 mb-3'
          src='https://www.youtube-nocookie.com/embed/BibTT_Dih0o'
          frameBorder='0'
          allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        />
      </Presentation>
      <Courses>
        <CoursesContent>
          <Title>Cursos Oferecidos</Title>
          <Subtitle className='mb-4'>Escola de aviação</Subtitle>

          <div className='row'>
            <div className='col'>
              <div className='card shadow-sm'>
                <CardImage image={piloto} />
                <div className='card-body'>
                  <h5 className='card-title'>Piloto Privado</h5>
                  <p className='card-text'>
                    Primeiro passo a ser galgado pelo candidato a piloto...
                  </p>
                  <CardMore to='/educacao/cursos/piloto-privado'>Ver mais</CardMore>
                </div>
              </div>
            </div>
            <div className='col'>
              <div className='card shadow-sm'>
                <CardImage image={manutencao} />
                <div className='card-body'>
                  <h5 className='card-title'>Mecânico de Avião</h5>
                  <p className='card-text'>
                    Todo avião necessita de inspeção e manutenção constantes...
                  </p>
                  <CardMore to='/educacao/cursos/mecanica-de-aviao'>Ver mais</CardMore>
                </div>
              </div>
            </div>
            <div className='col'>
              <div className='card shadow-sm'>
                <CardImage image={comissario} />
                <div className='card-body'>
                  <h5 className='card-title'>Comissário de Voo</h5>
                  <p className='card-text'>
                    Uma profissão admirada, que além da boa remuneração...
                  </p>
                  <CardMore to='/educacao/cursos/comissario-de-voo'>Ver mais</CardMore>
                </div>
              </div>
            </div>
          </div>
        </CoursesContent>
      </Courses>
      <Sports>
        <Title>Esportes</Title>
        <Subtitle>Oferecidos em nossas instalações</Subtitle>
        <SportsContent>
          <SportBox color='#10254A'>
            <SportImg src={tenis} />
            <SportName>Tênis</SportName>
          </SportBox>
          <SportBox color='#10254A'>
            <SportImg src={natacao} />
            <SportName>Natação</SportName>
          </SportBox>
          <SportBox color='#10254A'>
            <SportImg src={volei} />
            <SportName>Vôlei</SportName>
          </SportBox>
          <SportBox color='#10254A'>
            <SportImg src={judo} />
            <SportName>Judô</SportName>
          </SportBox>
        </SportsContent>
        <SportsMore to='/esportes'>Ver todos</SportsMore>
      </Sports>
    </Layout>
  )
}

export default IndexPage
